<template>
  <v-card elevation="1">
    <v-toolbar height="45" flat color="accent" light>
      <v-toolbar-title class="white--text">
        <v-icon class="white--text" left light>{{ icons.mdiAccountKeyOutline }}</v-icon>
        {{ $route.meta.title }}</v-toolbar-title
      >
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-card flat>
      <v-toolbar height="45" flat light>
        <v-toolbar-title class="secondary--text page-title">
          <v-icon class="secondary--text" left light>{{ icons.mdiAccount }}</v-icon> Users
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn class="mx-1" color="accent" rounded @click.prevent="addNewPermission">
          <v-icon left dark>{{ icons.mdiContentSave }}</v-icon>
          <span>Save</span>
        </v-btn>
        <v-btn class="mx-1" color="secondary" rounded @click.prevent="$router.push({ name: 'permission-list' })">
          <v-icon left dark>{{ icons.mdiClose }}</v-icon>
          <span>Cancel</span>
        </v-btn>
      </v-toolbar>
      <v-divider class="my-2"></v-divider>
      <v-card-text>
        <v-form class="multi-col-validation">
          <v-row>
            <v-col md="6" cols="12">
              <v-layout>
                <v-autocomplete
                  v-model="permission.user_id"
                  item-value="user_id"
                  :items="users"
                  item-text="username"
                  outlined
                  color="secondary"
                  dense
                  chips
                  deletable-chips
                  small-chips
                  label="User"
                  :prepend-inner-icon="icons.mdiAccount"
                  single-line
                ></v-autocomplete>
              </v-layout>
            </v-col>
            <v-col md="6" cols="12">
              <v-layout>
                <v-autocomplete
                  v-model="permission.role_id"
                  item-value="role_id"
                  :items="roles"
                  item-text="user_type"
                  outlined
                  color="secondary"
                  dense
                  chips
                  deletable-chips
                  small-chips
                  label="User Type"
                  :prepend-inner-icon="icons.mdiAccountKey"
                  single-line
                ></v-autocomplete>
              </v-layout>
            </v-col>
          </v-row>
        </v-form>
        <v-toolbar class="ml-n4" height="45" flat light>
          <v-toolbar-title class="secondary--text page-title">
            <v-icon class="secondary--text" left light>{{ icons.mdiMenu }}</v-icon> Menu and Submenu
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click.stop=""
                @click.prevent="addnewMenu"
                v-bind="attrs"
                v-on="on"
                class="mr-n5"
                fab
                dark
                x-small
                color="primary"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span>Add Menu</span>
          </v-tooltip>
        </v-toolbar>
        <v-expansion-panels inset>
          <v-expansion-panel v-for="(userMenu, index1) in permission.usermenus" :key="index1">
            <v-expansion-panel-header @keyup.space.prevent>
              <template v-slot:actions>
                <v-icon color="primary" class="icon mt-n5">{{ icons.mdiMenuDown }}</v-icon>
              </template>
              <v-layout class="header" row>
                <v-col class="mt-3" cols="12" md="4" sm="4">
                  <input type="hidden" v-model="userMenu.user_menu_id" />
                  <input type="hidden" v-model="userMenu.submenu" />
                  <input type="hidden" v-model="userMenu.permission_menuid" />
                  <v-autocomplete
                    v-model="userMenu.menu_id"
                    @click.native.stop=""
                    @input="getSubmenus(index1)"
                    @focus="getSubmenus(index1)"
                    item-value="menu_id"
                    :items="menus"
                    item-text="name"
                    outlined
                    color="secondary"
                    dense
                    chips
                    deletable-chips
                    small-chips
                    label="Menu"
                    single-line
                  ></v-autocomplete>
                </v-col>
                <div class="row mt-1">
                  <v-col cols="12" md="3" sm="3">
                    <v-switch
                      v-model="userMenu.create"
                      label="Create"
                      @click.stop
                      @click.prevent=""
                      color="primary"
                      :value="1"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="3" sm="3">
                    <v-switch
                      v-model="userMenu.read"
                      label="Read"
                      @click.stop
                      @click.prevent=""
                      color="primary"
                      :value="1"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="3" sm="3">
                    <v-switch
                      v-model="userMenu.update"
                      label="Update"
                      @click.stop
                      @click.prevent=""
                      color="primary"
                      :value="1"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="3" sm="3">
                    <v-switch
                      v-model="userMenu.delete"
                      label="Delete"
                      @click.stop
                      @click.prevent=""
                      color="primary"
                      :value="1"
                      hide-details
                    ></v-switch>
                  </v-col>
                </div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click.stop
                      @click.prevent="removeMenu(index1)"
                      v-bind="attrs"
                      v-on="on"
                      class="mt-8 mr-2"
                      fab
                      dark
                      x-small
                      color="error"
                    >
                      <v-icon dark> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Menu</span>
                </v-tooltip>
              </v-layout>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="userMenu.user_submenus"
                :hide-default-footer="true"
                sort-by="created_by"
                class="elevation-2"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(submenu, index2) in items" :key="index2">
                      <td width="25%">
                        <v-col cols="12" sm="12" md="12" class="ml-n4">
                          <input type="hidden" v-model="submenu.per_menu_id" />
                          <v-autocomplete
                            v-model="submenu.submenu_id"
                            :items="submenus"
                            item-value="submenu_id"
                            item-text="name"
                            outlined
                            color="secondary"
                            dense
                            chips
                            deletable-chips
                            small-chips
                            label="Submenu"
                            single-line
                          ></v-autocomplete>
                        </v-col>
                      </td>
                      <td width="10%">
                        <v-col cols="12" sm="12" md="12" class="mt-n11 ml-n4">
                          <v-switch
                            v-model="submenu.create"
                            @change="showSubmenu(index1)"
                            label="Create"
                            color="primary"
                            :value="1"
                            hide-details
                          ></v-switch>
                        </v-col>
                      </td>
                      <td width="10%">
                        <v-col cols="12" sm="12" md="12" class="mt-n11 ml-n4">
                          <v-switch
                            v-model="submenu.read"
                            @change="showSubmenu(index1)"
                            label="Read"
                            color="primary"
                            :value="1"
                            hide-details
                          ></v-switch>
                        </v-col>
                      </td>
                      <td width="10%">
                        <v-col cols="12" sm="12" md="12" class="mt-n11 ml-n4">
                          <v-switch
                            v-model="submenu.update"
                            @change="showSubmenu(index1)"
                            label="Update"
                            color="primary"
                            :value="1"
                            hide-details
                          ></v-switch>
                        </v-col>
                      </td>
                      <td width="10%">
                        <v-col cols="12" sm="12" md="12" class="mt-n11 ml-n4">
                          <v-switch
                            v-model="submenu.delete"
                            @change="showSubmenu(index1)"
                            label="Delete"
                            color="primary"
                            :value="1"
                            hide-details
                          ></v-switch>
                        </v-col>
                      </td>
                      <td>
                        <v-btn
                          class="mt-n6"
                          @click.prevent="removeSubMenu(index1, index2)"
                          color="error"
                          fab
                          x-small
                          dark
                        >
                          <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <v-btn @click.prevent="addnewSubMenu(index1)" plain color="primary">
                    <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                    Add Item
                  </v-btn>
                </template>
                <template v-slot:no-data>
                  <v-btn color="accent" @click="getPermissions"> Reset </v-btn>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import AddNewRoleDialog from '@/views/settings/role/AddNewRoleModal.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import {
  mdiAccountTie,
  mdiPlus,
  mdiInformation,
  mdiContentSave,
  mdiClose,
  mdiAccountCircle,
  mdiAccountKey,
  mdiAccountEdit,
  mdiAccountKeyOutline,
  mdiBackburger,
  mdiMenu,
  mdiMenuDown,
  mdiAccount,
  mdiDeleteForeverOutline,
} from '@mdi/js'

export default {
  components: {
    AddNewRoleDialog,
  },

  created: function () {
    this.getUsers()
    this.getRoles()
    this.getMenus()
    // window.mitt.on('loadRole', () => {
    //   this.getRoles()
    // })
  },
  data() {
    return {
      users: [],
      roles: [],
      menus: [],
      submenus: [],
      headers: [
        {
          text: 'Name',
          value: 'submenu_id',
        },

        {
          text: 'Create',
          value: 'create',
        },
        {
          text: 'Read',
          value: 'read',
        },
        {
          text: 'Update',
          value: 'update',
        },
        {
          text: 'Delete',
          value: 'delete',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      permission: {
        user_id: '',
        role_id: '',
        usermenus: [
          {
            user_menu_id: '',
            permission_id: '',
            menu_id: '',
            create: '',
            read: '',
            update: '',
            delete: '',
            submenu: '',
            user_submenus: [
              {
                user_submenu_id: '',
                user_menu_id: '',
                submenu_id: '',
                create: '',
                read: '',
                update: '',
                delete: '',
              },
            ],
          },
        ],
      },

      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiAccountCircle,
        mdiAccountKey,
        mdiAccountEdit,
        mdiAccountKeyOutline,
        mdiBackburger,
        mdiMenu,
        mdiAccount,
        mdiDeleteForeverOutline,
        mdiMenuDown,
      },
    }
  },
  methods: {
    getUsers: function () {
      this.$store
        .dispatch('user/fetchUsers')
        .then(response => {
          this.users = this.fetchUsers
        })
        .catch(err => {
          console.log(err)
        })
    },

    getRoles: function () {
      this.$store
        .dispatch('role/fetchRoles')
        .then(response => {
          this.roles = this.fetchRoles
        })
        .catch(err => {
          console.log(err)
        })
    },
    getMenus: function () {
      this.$store
        .dispatch('menu/fetchMenus')
        .then(response => {
          this.menus = this.fetchMenus
        })
        .catch(err => {
          console.log(err)
        })
    },
    getSubmenus: function (index1) {
      this.$store
        .dispatch('menu/fetchSubMenus', {
          menu_id: this.permission.usermenus[index1].menu_id,
        })
        .then(response => {
          this.submenus = this.fetchSubMenus
        })
        .catch(err => {
          console.log(err)
        })
    },

    showSubmenu: function (index1) {
      this.permission.usermenus[index1].submenu = 1
    },

    addNewPermission: function () {
      this.$store
        .dispatch(`permission/storeNewPermission`, this.permission)
        .then(response => {
          this.$router.push({ name: 'permission-list' })
        })
        .catch(error => {
          console.log(error)
        })
    },

    addnewRole: function () {
      this.showNewRoleDialog = true
    },
    addnewMenu: function () {
      this.permission.usermenus.push({
        user_menu_id: '',
        permission_id: '',
        menu_id: '',
        create: '',
        read: '',
        update: '',
        delete: '',
        submenu: '',
        user_submenus: [],
      })
    },

    removeMenu: function (index1) {
      this.permission.usermenus.splice(index1, 1)
    },

    addnewSubMenu: function (index1) {
      this.permission.usermenus[index1].user_submenus.push({
        user_submenu_id: '',
        user_menu_id: '',
        submenu_id: '',
        create: '',
        read: '',
        update: '',
        delete: '',
      })
    },

    removeSubMenu: function (index1, index2) {
      this.permission.usermenus[index1].user_submenus.splice(index2, 1)
    },

    formatDate(value) {
      return moment(value).format('l')
    },
  },
  computed: {
    ...mapGetters({
      fetchPermissions: 'permission/fetchPermissions',
      fetchUsers: 'user/fetchUsers',
      fetchRoles: 'role/fetchRoles',
      fetchMenus: 'menu/fetchMenus',
      fetchSubMenus: 'menu/fetchSubMenus',
    }),
  },
}
</script>

<style>
.icon {
  order: 0;
}
.header {
  order: 1;
}
</style>